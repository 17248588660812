import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SuccessStory from "../../components/success-stories/SuccessStory"

const SuccessStoryPage = ({ data }) => {
  return (
    <Layout>
      <SuccessStory data={data} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String = "") {
    mdx(id: { eq: $id }) {
      frontmatter {
        city
        country
        date
        headline
        level
        name
        niche
        rating
        state
        summary
        title
        youtube_id
        youtube_title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        image_alt
      }
      body
      slug
    }
  }
`
export default SuccessStoryPage

export const allClientsData = [
  {
    allMdx: {
      nodes: [
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Eduarado Gonzalez Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#080808",
                  images: {
                    fallback: {
                      src: "/static/f30f02ea5b00d5e13363848764c44e28/4a49b/eduardo-gonzalez.jpg",
                      srcSet:
                        "/static/f30f02ea5b00d5e13363848764c44e28/b4dad/eduardo-gonzalez.jpg 320w,\n/static/f30f02ea5b00d5e13363848764c44e28/3440d/eduardo-gonzalez.jpg 640w,\n/static/f30f02ea5b00d5e13363848764c44e28/4a49b/eduardo-gonzalez.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/f30f02ea5b00d5e13363848764c44e28/c0bcc/eduardo-gonzalez.webp 320w,\n/static/f30f02ea5b00d5e13363848764c44e28/17574/eduardo-gonzalez.webp 640w,\n/static/f30f02ea5b00d5e13363848764c44e28/71d4d/eduardo-gonzalez.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Eduarado Gonzalez",
            city: "Washington",
            country: "USA",
            level: 5,
            name: "Eduarado Gonzalez",
            first_name: "Eduarado",
            niche: "Filmmaker",
            rating: 5,
            state: "D.C.",
            headline:
              "Why buying a Red or Arri camera won’t make you a wealthy filmmaker with Eduardo G.",
            summary:
              "Eduardo explains the frustration of spending more and more on camera gear, extra PA help, all while chasing the next project with no certainty that it would actually happen. Since joining Next Level Creators, Eduardo has completely shifted his business model to insure that he has monthly income streaming in while eliminating many of his old production headaches.",
            youtube_id: "8ipxx2GyVis",
            youtube_title:
              "Why Buying A Red Or Arri Camera Won't Make You A Wealthy Filmmaker With Eduardo Gonzalez",
          },
          id: "f7b19b95-df5b-57d5-b064-7b6974027cf0",
          slug: "filmmaker/why-buying-a-red-or-arri-camera-will-not-make-you-a-wealth-filmmaker-with-eduardo-g/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Jordan A. Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#f8f8f8",
                  images: {
                    fallback: {
                      src: "/static/b9e1187b39e463d0020153b95f487178/4a49b/jordan-a.jpg",
                      srcSet:
                        "/static/b9e1187b39e463d0020153b95f487178/b4dad/jordan-a.jpg 320w,\n/static/b9e1187b39e463d0020153b95f487178/3440d/jordan-a.jpg 640w,\n/static/b9e1187b39e463d0020153b95f487178/4a49b/jordan-a.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/b9e1187b39e463d0020153b95f487178/c0bcc/jordan-a.webp 320w,\n/static/b9e1187b39e463d0020153b95f487178/17574/jordan-a.webp 640w,\n/static/b9e1187b39e463d0020153b95f487178/71d4d/jordan-a.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Jordan A.",
            city: "Atlanta",
            country: "USA",
            level: 5,
            name: "Jordan A.",
            first_name: "Jordan",
            niche: "Video Media Agency",
            rating: 5,
            state: "GA",
            headline: "How Jordan Doubled Force Media In Less Than 12 Months",
            summary:
              "Jordan A explains how the Next Level Creators program helped him save his video production company Force Media, double his sales, move into an office and start working with better clients.",
            youtube_id: "0yBsBHEqJvo",
            youtube_title:
              "How Jordan doubled his video production companies revenue to $200k a year",
          },
          id: "705a93ae-27ea-53bd-ad05-0b247ce0c8aa",
          slug: "video-media-agency/how-jordan-doubled-force-media-in-less-than-12-months/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Timi David Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#080808",
                  images: {
                    fallback: {
                      src: "/static/d06dfcc8d452889e19b7d147ba0b49a6/4a49b/timi-david.jpg",
                      srcSet:
                        "/static/d06dfcc8d452889e19b7d147ba0b49a6/b4dad/timi-david.jpg 320w,\n/static/d06dfcc8d452889e19b7d147ba0b49a6/3440d/timi-david.jpg 640w,\n/static/d06dfcc8d452889e19b7d147ba0b49a6/4a49b/timi-david.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/d06dfcc8d452889e19b7d147ba0b49a6/c0bcc/timi-david.webp 320w,\n/static/d06dfcc8d452889e19b7d147ba0b49a6/17574/timi-david.webp 640w,\n/static/d06dfcc8d452889e19b7d147ba0b49a6/71d4d/timi-david.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Timi David",
            city: "London",
            country: "UK",
            level: 5,
            name: "Timi David",
            first_name: "Timi",
            niche: "Filmmaker",
            rating: 5,
            state: "England",
            headline: "Timi David Imagines Life Without Next Level Creators",
            summary:
              "Imagine working project to project with no control over your income as a freelance filmmaker for YEARS… Thats exactly what Timi David did in this short video review of life after implementing the Creators Operating System.",
            youtube_id: "q2S5LxNaszw",
            youtube_title:
              "Timi David Imagines Life Without Next Level Creators",
          },
          id: "f645775b-fe43-5283-bb18-a3615af26e82",
          slug: "filmmaker/timi-david-imagines-life-without-next-level-creators/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Lucinda Chrisman Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#989898",
                  images: {
                    fallback: {
                      src: "/static/e6d1da661bd78026be8962940575191b/4a49b/lucinda-chrisman.jpg",
                      srcSet:
                        "/static/e6d1da661bd78026be8962940575191b/b4dad/lucinda-chrisman.jpg 320w,\n/static/e6d1da661bd78026be8962940575191b/3440d/lucinda-chrisman.jpg 640w,\n/static/e6d1da661bd78026be8962940575191b/4a49b/lucinda-chrisman.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/e6d1da661bd78026be8962940575191b/c0bcc/lucinda-chrisman.webp 320w,\n/static/e6d1da661bd78026be8962940575191b/17574/lucinda-chrisman.webp 640w,\n/static/e6d1da661bd78026be8962940575191b/71d4d/lucinda-chrisman.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Lucinda Chrisman",
            city: "Santa Cruz",
            country: "USA",
            level: 5,
            name: "Lucinda Chrisman",
            first_name: "Lucinda",
            niche: "Filmmaker",
            rating: 5,
            state: "CA",
            headline:
              "Lucinda Chrisman Raises Her Video Production Prices With Confidence",
            summary:
              "How Next Level Creators helped Lucinda raise her video production prices & find confidence as a business owner.",
            youtube_id: "KmTArHmMQu4",
            youtube_title:
              "How Lucinda Has Increased Her Video Production Prices By Offering More Value",
          },
          id: "8aff6f40-f16f-5d24-8247-d4ebfd7611c2",
          slug: "filmmaker/lucinda-chrisman-raises-her-video-production-prices-with-confidence/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "James Cooper Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#f8f8f8",
                  images: {
                    fallback: {
                      src: "/static/40e664a19c87196112829aa26c358308/4a49b/james-cooper.jpg",
                      srcSet:
                        "/static/40e664a19c87196112829aa26c358308/b4dad/james-cooper.jpg 320w,\n/static/40e664a19c87196112829aa26c358308/3440d/james-cooper.jpg 640w,\n/static/40e664a19c87196112829aa26c358308/4a49b/james-cooper.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/40e664a19c87196112829aa26c358308/c0bcc/james-cooper.webp 320w,\n/static/40e664a19c87196112829aa26c358308/17574/james-cooper.webp 640w,\n/static/40e664a19c87196112829aa26c358308/71d4d/james-cooper.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "James Cooper",
            city: "Los Angeles",
            country: "USA",
            level: 5,
            name: "James Cooper",
            first_name: "James",
            niche: "Filmmaker",
            rating: 5,
            state: "CA",
            headline:
              "How James Cooper Scaled His Commercial Film Business To A Consistent 5-Figures",
            summary:
              "James Cooper discusses the need to pivot his business from project to project to consistent, reliable monthly income so that he could focus on raising his two young sons.",
            youtube_id: "pTPD1DBFK2Q",
            youtube_title:
              "From Zero To 5-Figures A Month - James Freelance Content Creator Story",
          },
          id: "35a428b8-461d-5ece-9c9d-0f0ba7978a04",
          slug: "filmmaker/how-james-cooper-scaled-his-commercial-film-business-to-a-consistent-5-figures/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Drew Hall Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#181818",
                  images: {
                    fallback: {
                      src: "/static/ad4bbbab711f233034abbf47ad7dd065/4a49b/drew-hall.jpg",
                      srcSet:
                        "/static/ad4bbbab711f233034abbf47ad7dd065/b4dad/drew-hall.jpg 320w,\n/static/ad4bbbab711f233034abbf47ad7dd065/3440d/drew-hall.jpg 640w,\n/static/ad4bbbab711f233034abbf47ad7dd065/4a49b/drew-hall.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/ad4bbbab711f233034abbf47ad7dd065/c0bcc/drew-hall.webp 320w,\n/static/ad4bbbab711f233034abbf47ad7dd065/17574/drew-hall.webp 640w,\n/static/ad4bbbab711f233034abbf47ad7dd065/71d4d/drew-hall.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Drew Hall",
            city: "Mobile",
            country: "USA",
            level: 5,
            name: "Drew Hall",
            first_name: "Drew",
            niche: "Filmmaker",
            rating: 5,
            state: "AL",
            headline:
              "How Drew Hall Took CraftShow From $8k/Month To $30k/Month",
            summary:
              "Drew Hall explains how the Next Level Creators program helped push him beyond what he thought his business was capable of being. The strategies and principles he learned and implemented helped him adjust to a new standard of living for the better.",
            youtube_id: "dbFaHwf6lnE",
            youtube_title:
              "Drew Hall Says It's Time To Think Bigger As A Filmmaker",
          },
          id: "ecf18ef4-0964-5d75-b52e-e5b67a180077",
          slug: "filmmaker/how-drew-hall-took-craftshow-from-dollar8k-per-month-to-dollar30k-per-month/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Chris Stanley Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#f8f8e8",
                  images: {
                    fallback: {
                      src: "/static/b21001c250c7fbfc714d5a94e9b51ff5/4a49b/chris-stanley.jpg",
                      srcSet:
                        "/static/b21001c250c7fbfc714d5a94e9b51ff5/b4dad/chris-stanley.jpg 320w,\n/static/b21001c250c7fbfc714d5a94e9b51ff5/3440d/chris-stanley.jpg 640w,\n/static/b21001c250c7fbfc714d5a94e9b51ff5/4a49b/chris-stanley.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/b21001c250c7fbfc714d5a94e9b51ff5/c0bcc/chris-stanley.webp 320w,\n/static/b21001c250c7fbfc714d5a94e9b51ff5/17574/chris-stanley.webp 640w,\n/static/b21001c250c7fbfc714d5a94e9b51ff5/71d4d/chris-stanley.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Chris Stanley",
            city: "Vancouver",
            country: "Canada",
            level: 5,
            name: "Chris Stanley",
            first_name: "Chris",
            niche: "Cinematographer",
            rating: 5,
            state: "BC",
            headline:
              "The Truth About Paul Xavier & Next Level Creators With Chris Stanley",
            summary:
              "Chris Stanley talks the “peaks and valleys” struggle of his old way of doing business as a filmmaker. Set against the backdrop of beautiful  Vancouver, Chris praises the lessons he has learned and the connections he has made which have helped his business thrive.",
            youtube_id: "gDPrFClUP0g",
            youtube_title:
              "The Truth About Paul Xavier & Next Level Creators With Chris Stanley",
          },
          id: "08b35f51-f5a3-5892-872c-fd622f2b5c64",
          slug: "cinematographer/the-truth-about-paul-xavier-and-next-level-creators-with-chris-stanley/",
        },
        {
          frontmatter: {
            date: "July 23, 2021",
            title: "Aaron Greer Success Story",
            image: {
              childImageSharp: {
                gatsbyImageData: {
                  layout: "constrained",
                  backgroundColor: "#181818",
                  images: {
                    fallback: {
                      src: "/static/efdc9f30b066f5a39c383aff618d5d54/4a49b/aaron-greer.jpg",
                      srcSet:
                        "/static/efdc9f30b066f5a39c383aff618d5d54/b4dad/aaron-greer.jpg 320w,\n/static/efdc9f30b066f5a39c383aff618d5d54/3440d/aaron-greer.jpg 640w,\n/static/efdc9f30b066f5a39c383aff618d5d54/4a49b/aaron-greer.jpg 1280w",
                      sizes: "(min-width: 1280px) 1280px, 100vw",
                    },
                    sources: [
                      {
                        srcSet:
                          "/static/efdc9f30b066f5a39c383aff618d5d54/c0bcc/aaron-greer.webp 320w,\n/static/efdc9f30b066f5a39c383aff618d5d54/17574/aaron-greer.webp 640w,\n/static/efdc9f30b066f5a39c383aff618d5d54/71d4d/aaron-greer.webp 1280w",
                        type: "image/webp",
                        sizes: "(min-width: 1280px) 1280px, 100vw",
                      },
                    ],
                  },
                  width: 1280,
                  height: 720,
                },
              },
            },
            image_alt: "Aaron Greer",
            city: "Grand Rapids",
            country: "USA",
            level: 5,
            name: "Aaron Greer",
            first_name: "Aaron",
            niche: "Cinematographer",
            rating: 5,
            state: "MI",
            headline:
              "Aaron Greer Explains Why There’s More To Successful Filmmaking Than A RED Camera",
            summary:
              "Aaron Greer explains why a RED camera does not always equal success in the filmmaking world. After making the decision to grow his family, Aaron realized that he needed to grow his business as well to support it.",
            youtube_id: "YI2IQ-G_jfY",
            youtube_title:
              "Aaron Greer Explains Why There's More To Successful Filmmaking Than A RED Camera",
          },
          id: "ff15b31a-6c62-55ef-a0b1-19599668ea17",
          slug: "cinematographer/aaron-greer-explains-why-there-is-more-to-successful-filmmaking-than-a-red-camera/",
        },
      ],
    },
  },
]

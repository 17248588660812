import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import SocialFooter from "../main/SocialFooter"
import DisclosureFooter from "../main/DisclosureFooter"
import { AiFillStar } from "react-icons/ai"
import { BiPlay } from "react-icons/bi"
import SuccessStoriesModal from "./SuccessStoriesModal"
import { allClientsData } from "../../data/SuccessStoriesData"

export default function SuccessStories() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState(0)
  const [selectedClient, setSelectedClient] = useState()

  useEffect(() => {
    if (selectedClient) {
      setModalIsOpen(true)
    }
  }, [selectedClient])

  function handleImageClick(id) {
    setSelectedClientId(id)
    setSelectedClient(
      allClientsData[0].allMdx.nodes.find(node => node.id === id)
    )
  }

  const handleModalClose = () => {
    setSelectedClient(null)
    setModalIsOpen(false)
  }

  // Freeze the body background if the modal is open
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [modalIsOpen])

  return (
    <>
      <Body>
        <SuccessStoriesModal
          modalIsOpen={modalIsOpen}
          onModalClose={handleModalClose}
          selectedClientId={selectedClientId}
          selectedClient={selectedClient}
        ></SuccessStoriesModal>
        <TestimonialContainer className="success-stories__testimonial-container" />

        <StoryCardContainer>
          {allClientsData[0].allMdx.nodes.map(node => (
            <StoryCard key={node.id}>
              <ClientImageContainer
                id={node.id}
                className="success-stories__client-image-container"
                onClick={() => handleImageClick(node.id)}
              >
                <PlayCircle className="success-stories__play-circle">
                  <PlayIcon className="success-stories__play-icon" />
                </PlayCircle>
                <GatsbyImage
                  image={getImage(node.frontmatter.image)}
                  alt={node.frontmatter.image_alt}
                />
              </ClientImageContainer>
              <ClientInfoContainer>
                <ClientInfo>
                  <ClientName>{node.frontmatter.name}</ClientName>
                  <ClientLocation>
                    {node.frontmatter.city},&nbsp;
                    {node.frontmatter.state}
                  </ClientLocation>
                </ClientInfo>
                <ClientRating>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </ClientRating>
              </ClientInfoContainer>
            </StoryCard>
          ))}
        </StoryCardContainer>
      </Body>
      <SocialFooter />
      <section className="themes__panel-black">
        <DisclosureFooter />
      </section>
    </>
  )
}

const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
  padding: 30px;
  margin: 0;
  border: 0;
  padding-top: 40px;
  background-color: #fff;
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;

  @media (max-width: 1199px) {
  }

  @media (max-width: 970px) {
  }

  @media (max-width: 620px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

const StoryCardContainer = styled.div`
  display: grid;
  gap: 30px;
  justify-content: space-around;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1200px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 970px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`
const StoryCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 20px 50px rgb(0 0 0 / 20%);

  @media (max-width: 1199px) {
    max-width: 336px;
  }

  @media (max-width: 970px) {
    max-width: 448px;
  }

  @media (max-width: 620px) {
    max-width: 620px;
  }

  &:hover {
    box-shadow: 0 20px 50px rgb(0 0 0 / 40%);
  }
`

const ClientImageContainer = styled.div``

const ClientInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  font-style: inherit;
  height: 90px;
  width: 100%;
  cursor: pointer;
`
const ClientInfo = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const ClientRating = styled.div`
  margin-top: 10px;
  padding-right: 5px;
`

const ClientName = styled.h5`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #000000;
  padding: 0;
  margin: 0;
`
const ClientLocation = styled.div`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #8a8a8a;
  padding: 5px 0 0 0;
  margin: 0;
`
const StarIcon = styled(AiFillStar)`
  font-size: 16px;
  color: #ffc15b;
`
const PlayCircle = styled.div``

const PlayIcon = styled(BiPlay)``

const TestimonialContainer = styled.div``
